import './index.scss';
import '../../components/js/header.js';
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
import { trackInit } from '../../assets/js/track';
import { getIP } from '../../assets/js/ADHUB';

checkWeChat();

window.addEventListener('load', windowLoad);
window.addEventListener('scroll', handleScroll);

function windowLoad() {
  getIP();
  trackInit();
  initSwiper();
}

/**
 * 点击下载按钮弹到屏幕顶部
 */
$('.home-interest__content').click(() => {
  $('html, body').animate({ scrollTop: 0 }, 'slow');
});

/**
 * * 滚动监听
 */
function handleScroll() {
  const scrollTop = $(window).scrollTop();
  const { clientHeight } = document.documentElement;
  if (scrollTop >= clientHeight) {
    $('.header').addClass('active');
  } else {
    $('.header').removeClass('active');
  }
}

/**
 * * 初始化swiper
 */
function initSwiper() {
  new Swiper('.swiper-container', {
    // 如果需要前进后退按钮
    loop: true,
    autoplay: false,
    slidesPerView: 'auto',
    spaceBetween: 10,
    loopedSlides: 10,
    centeredSlides: true,
    centeredSlidesBounds: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    on: {
      init: function () {
        $('.home-category').width(1092);
      },
    },
  });
}

/**
 * * 判断是否为微信浏览器
 */
function checkWeChat() {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.includes('micromessenger')) {
    $('.main-download__modal').addClass('active');
    $('.popup-window').addClass('active');
  }
}

/**
 * * 点击显示下载引导弹窗
 */
$('.main-download__modal.active').click(() => {
  $('.download-tip__modal').fadeIn();
})

/**
 * * 点击关闭下载引导弹窗
 */
$('.download-tip__modal').click(() => {
  $('.download-tip__modal').fadeOut();
})

/**
 * * 判断安卓还是ios
 */
$('.popup-window.active').click(() => {
  var u = navigator.userAgent;
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  if (isAndroid) {
    $('.download-tip__modal').fadeIn();
    $('.popup-window').fadeOut();
  } else if (isiOS) {
    window.location.href = 'https://apps.apple.com/cn/app/linggan/id1478843357'
  }
})

/**
 * * 点击关闭去体验弹窗
 */
