
// 获取当前用户ip
export function getIP() {
  $.get("https://t.fideo.com.cn/who_am_i", function (data) {
    ADHUB(data.ip, data.ua_platform, data.User_Agent);
  });
}

// ADHUB监测工具
function ADHUB(ip, ua_platform, User_Agent) {
  // 需要替换的小白的链接
  // var currentLink = 'https://t.fideo.com.cn/cb/installation_track?idfa=__IDFA__&imei=__IMEI__&android_id=__ANDROID_ID__&ip=__IP__&os=__OS__&ua=__UA__&ts=__TS__&callback_url=__CALLBACK_URL__&project=fideo_v1&src=adhub_web&utm_source=ADHUB%E7%82%B9%E5%87%BB%E8%BD%AC%E6%8D%A2%E5%99%A8&utm_medium=adhub_web&utm_content=__URL_DOMAIN__&utm_campaign=Ben&utm_term=__URL_PATH__';
  // 将 需要替换的小白的链接 中的参数解析成对象
  // var currentLinkUrlObj = urlParse(currentLink);

  // 获取当前页面的参数解析成对象
  const urlObj = urlParse(window.location.search);
  var query = getReal({
    android_id: urlObj.ads_ANDROIDID || urlObj.ads_ANDROIDID_MD5 || '', // 如果arg里有ads_ANDROIDID优先使用，否则使用ads_ANDROIDID_MD5，如果两个都没有，则直接传空
    callback_url: '', // ! 如果有callback_url优先使用，否则查找其他http开头的参数，如果都找不到，传空 !!!!!!!!!!! 需要问问小白
    idfa: urlObj.ads_IDFA || urlObj.ads_IDFA_MD5 || '', // 如果arg里有ads_IDFA优先使用，否则使用ads_IDFA_MD5，如果两个都没有，则直接传空
    imei: urlObj.ads_IMEI || urlObj.ads_IMEI_MD5 || '', // 如果arg里有ads_IMEI优先使用，没有则使用ads_IMEI_MD5，如果两个都没有，则直接传空
    ip: ip, // 获取用户的ip
    os: ua_platform, // 获取用户的操作系统类型
    ts: Math.round(new Date().getTime() / 1000).toString(), // 当前时间的10位时间戳
    utm_content: location.host, // 网址域名
    utm_term: location.pathname, // 网址的路径
    src: "adhub_web",
    project: "fideo_v1",
    utm_campaign: "Ben",
    utm_medium: "adhub_web",
    utm_source: "ADHUB点击转换器",
    ua: User_Agent, // 获取用户的User-Agents
  })

  if (query.android_id || query.idfa || query.imei) {
    query = splicingUrlQuery(query);
    // console.log(query, '请求接口')
    // 请求接口
    $.get(`https://t.fideo.com.cn/cb/installation_track?${query}`, function (data) {
      console.log('ADHUB监测开始')
    });
  }
}

// 参数排空
function getReal(obj) {
  for (let key in obj) {
    // console.log(key + '---' + obj[key])
    !obj[key] && delete obj[key];
  }
  return obj;
}

// 拼接url参数
function splicingUrlQuery(data) {
  let ret = '';
  for (let it in data) {
    if (data[it]) {
      ret +=
        encodeURIComponent(it) +
        '=' +
        encodeURIComponent(data[it]) +
        '&';
    }
  }
  return ret;
}

// 将url中的参数解析成对象
function urlParse(url) {
  let obj = {}
  let reg = /[?&][^?&]+=[^?&]+/g
  let arr = url.match(reg)
  if (arr) {
    arr.forEach((item) => {
      let tempArr = item.substr(1).split('=')
      let key = decodeURIComponent(tempArr[0])
      let val = decodeURIComponent(tempArr[1])
      obj[key] = val
    })
  }
  return obj
}